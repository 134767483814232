import React, { useState, Fragment, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box,
  FormLabel, FormControlLabel, CircularProgress, FormControl, InputLabel, MenuItem,
  FormHelperText, Select, RadioGroup, Radio, Typography } from '@material-ui/core';
import { CSVReader } from 'react-papaparse';
import PropTypes from 'prop-types';

import { eeccApi } from 'src/services';
import { getCatalogsInfo as getFloraCatalogs } from 'src/services/flora';
import { getCatalogsInfo as getFaunaCatalogs } from 'src/services/fauna';


const EeccMatcherDialog = ({ customButton: CustomButton }) => {
  const [ open, setOpen ] = useState(false);
  const [ jsonCsv, setJsonCsv ] = useState({ header: [], values: [] });
  const [ speciesNameColumn, setSpeciesNameColumn ] = useState('');
  const [ matchTarget, setMatchTarget ] = useState('ministry');
  const [ csvResult, setCsvResult ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ faunaCatalogs, setFaunaCatalogs ] = useState([]);
  const [ floraCatalogs, setFloraCatalogs ] = useState([]);
  const [ catalogOptions, setCatalogOptions ] = useState(null);
  const [ loadingCatalogs, setLoadingCatalogs ] = useState(true);
  const [ selectedCatalogId, setSelectedCatalogId ] = useState(null);


  useEffect(() => {
    const fetchSources = async () => {
      setLoadingCatalogs(true);

      const [ fetchedFaunaCatalogs, fetchedFloraCatalogs ] = await Promise.all([
        getFaunaCatalogs({ excludeNeverApproved: true, excludeEmpty: true }),
        getFloraCatalogs({ excludeNeverApproved: true, excludeEmpty: true }),
      ]);

      setFloraCatalogs(fetchedFloraCatalogs);
      setFaunaCatalogs(fetchedFaunaCatalogs);

      setLoadingCatalogs(false);
    };
    fetchSources();
  }, []);

  // set catalog options to display based on whether they selected flora, fauna or ministry ()
  useEffect(() => {
    if (!loadingCatalogs && matchTarget !== 'ministry') {
      const relevantCatalogs = matchTarget === 'flora' ? floraCatalogs : faunaCatalogs;
      setCatalogOptions(relevantCatalogs);
      // if there's just one option, select it right away instead of making the user unnecessarily do it
      if (relevantCatalogs && relevantCatalogs.length === 1) {
        setSelectedCatalogId(relevantCatalogs[0].id);
      }
    } else if (matchTarget === 'ministry') {
      setCatalogOptions(null);
      setSelectedCatalogId(null);
    }
  // eslint-disable-next-line
  }, [ matchTarget, loadingCatalogs ]);

  const onCloseModal = () => {
    setJsonCsv({ header: [], values: [] });
    setSpeciesNameColumn('');
    setMatchTarget('ministry');
    setCsvResult('');
    setLoading(false);
    setOpen(false);
  };

  const onLoadCsv = csvParsed => {
    const [ header, ...data ] = csvParsed;
    const jsonCsv = {
      header: header.data,
      values: data.map(d => d.data.reduce((p, c, i) => ({ ...p, [header.data[i]]: c }), {})),
    };
    setJsonCsv(jsonCsv);
  };

  const onRemoveCsv = () => {
    setSpeciesNameColumn('');
    setJsonCsv({ header: [], values: [] });
  };

  const matchCsvWithEecc = async () => {
    setLoading(true);
    try {
      const data = {
        header: jsonCsv.header,
        species: jsonCsv.values,
        options: {
          speciesNameColumn,
          matchTarget,
        },
        sourceIds: matchTarget !== 'ministry' ? catalogOptions.find(cat => cat.id === selectedCatalogId).source_ids : [],
      };
      const resp = await eeccApi.matchSpecies(data);
      setCsvResult(encodeURI(resp));
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const handleTargetChange = e => {
    setSelectedCatalogId(null);
    setMatchTarget(e.target.value);
  };

  const handleCatalogChange = e => {
    const catalogId = e.target.value;
    setSelectedCatalogId(catalogId);
  };

  const disabledMatch = speciesNameColumn === '' || loading || Boolean(csvResult) ||
    (matchTarget !== 'ministry' && (loadingCatalogs || !selectedCatalogId));

  return <Fragment>
    {
      CustomButton ?
        <CustomButton defaultOnClick={ () => setOpen(true) } /> :
        <Button variant="contained" color="primary" onClick={ () => setOpen(true) }>Matcher</Button>
    }
    <Dialog open={ open } onClose={ onCloseModal } aria-labelledby="matcher-modal-title">
      <DialogTitle id="matcher-modal-title">
        Matcher de Categorías de Conservación
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Aquí puedes subir un listado de especies en formato <strong>csv</strong> para obtener sus categorías de conservación,
          ten presente lo siguiente:<br/>
        </DialogContentText>
        <DialogContentText style={ { paddingLeft: 15, fontSize: 14 } }>
          <strong>*</strong> Esta búsqueda funciona si el nombre de la especie dado está contenido en los nombres que tenemos ignorando
          mayúsculas y espacios extras. Aquí será válido un match del tipo { '"' }<em>Acacia <strong>caven</strong> caven</em>{ '" ' }
          donde el término de la búsqueda brindado en este caso es <strong>caven</strong><br/>

          <strong>*</strong> Debes tener <strong>cuidado</strong> ya que es posible de que se produzca una <strong>duplicación
          de especies</strong> si la especie que diste hace match con más de una en nuestros listados. Puedes ver eso con ayuda de la
          columna <strong>csw_id</strong> la cual tendrá valores repetidos en este caso. <strong>ESTO PARASARÁ CON MAYOR PROBABILIDAD
          AL USAR <em>MADRE FLORA</em> O <em>MADRE FAUNA</em></strong><br/>

          <strong>*</strong> Los listados <em>Madre Flora</em> y <em>Madre Fauna</em> son construidos por <strong>CSW</strong> mediante
          recolección de datos de distintas fuentes las cuales podrás ver en la columna <strong>source_id</strong>.
        </DialogContentText>
        <Box display={ csvResult || loading ? 'none' : '' }>
          <FormLabel>
            Selecciona un archivo
          </FormLabel>
          <Box height="100">
            <CSVReader
              onDrop={ onLoadCsv }
              onError={ err => console.error(err) }
              onRemoveFile={ onRemoveCsv }
              addRemoveButton
            >
              <Box component='span'>Arrastra un CSV aquí o has click para cargar uno.</Box>
            </CSVReader>
          </Box>
          <FormControl required>
            <InputLabel id="select-label">Columna</InputLabel>
            <Select
              labelId="select-label"
              value={ speciesNameColumn }
              onChange={ e => setSpeciesNameColumn(e.target.value) }
            >
              {
                jsonCsv.header.length ?
                  jsonCsv.header.map((h, i) => <MenuItem key={ i } value={ h }>{ h }</MenuItem>) :
                  <MenuItem value="">-</MenuItem>
              }
            </Select>
            <FormHelperText>Columna donde está el nombre de la especie</FormHelperText>
          </FormControl>
          <br/><br/>
          <FormControl component="fieldset">
            <FormLabel component="legend">¿Contra qué listado de especies quieres hacer match?</FormLabel>
            <RadioGroup row value={ matchTarget } onChange={ handleTargetChange }>
              <FormControlLabel value="ministry" control={<Radio />} label="Ministerio" />
              <FormControlLabel value="flora" control={<Radio />} label="Madre Flora"/>
              <FormControlLabel value="fauna" control={<Radio />} label="Madre Fauna"/>
            </RadioGroup>
          </FormControl>
          { matchTarget !== 'ministry' &&
            ( loadingCatalogs ?
              <Box display='flex' justifyContent="center"><CircularProgress /></Box> :
              ( catalogOptions?.length > 1 ?
                <FormControl component="fieldset">
                  <Box>
                    <FormControl required>
                      <InputLabel id="catalog-select-label">Catálogo</InputLabel>
                      <Select
                        labelId='catalog-selector-label'
                        value={ selectedCatalogId ?? '' }
                        onChange={ handleCatalogChange }
                        displayEmpty
                      >
                        { catalogOptions.map(o => <MenuItem key={ o.id } value={ o.id }>{ o.display_name }</MenuItem>) }
                      </Select>
                      <FormHelperText>Catálogo con el que se compararán los nombres de especie</FormHelperText>
                    </FormControl>
                  </Box>
                </FormControl> :
                <Box mx={2} my={1}>
                  { !catalogOptions || catalogOptions.length === 0 ?
                    <Typography>No hay catálogos disponibles</Typography> :
                    <Typography>Catálogo: { catalogOptions[0].display_name }</Typography>
                  }
                </Box>
              )
            )
          }
        </Box>
        <Box display={ csvResult || loading ? 'flex' : 'none' } justifyContent="center">
          { loading ?
            <CircularProgress /> :
            <Button href={ `data:application/octet-stream,${ csvResult }` } download="resultados_del_match.csv"
              color="primary" variant="contained">
              Descargar resultados
            </Button>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={ onCloseModal } color="primary">Cerrar</Button>
        <Button
          disabled={ disabledMatch }
          onClick={ matchCsvWithEecc } color="primary">
            Matchear
        </Button>
      </DialogActions>
    </Dialog>
  </Fragment>;
};

EeccMatcherDialog.propTypes = {
  customButton: PropTypes.func,
};


export { EeccMatcherDialog };