import React, { useMemo } from 'react';
import PropTypes from 'prop-types';


export const SourcesContext = React.createContext({});

const initialSourcesState = {
  sources: [],
  selectedSourceId: null,
};

const sourcesReducer = (state, action) => {
  switch (action.type) {
    case 'select': {
      return { ...state, selectedSourceId: action.payload };
    }
    case 'setSources': {
      const { newSources, selectedSourceId } = action.payload;
      // Let's default to selecting the first one (the backend returns them ordered by display_name)
      const newSelected = selectedSourceId !== undefined ? selectedSourceId : (newSources.length ? newSources[0].id : null);
      return { sources: newSources, selectedSourceId: newSelected };
    }
    case 'setDefault': {
      return initialSourcesState;
    }
    default:
      return state;
  }
};

export const SourcesContextProvider = ({ children }) => {
  const [ state, dispatch ] = React.useReducer(sourcesReducer, initialSourcesState);

  const selectSource = sourceId => {
    dispatch({ type: 'select', payload: sourceId });
  };

  const setSources = ({ newSources, selectedSourceId }) => {
    dispatch({ type: 'setSources', payload: { newSources, selectedSourceId } });
  };

  const setDefault = () => {
    dispatch({ type: 'setDefault' });
  };

  const sourcesContextValue = useMemo(() => {
    return { ...state, selectSource, setSources, setDefault };
    // eslint-disable-next-line
  }, [ state ]);

  return (
    <SourcesContext.Provider value={ sourcesContextValue }>
      { children }
    </SourcesContext.Provider>
  );
};

SourcesContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

