import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

import { SourcesContext } from 'src/contexts';


const escapeRegExp = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const useStyles = makeStyles(theme => ({
  speciesName: {
    fontStyle: 'italic',
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  match: {
    color: theme.palette.info.main,
  },
}));


const getScientificName = sp => sp.scientific_name || sp.scientificName;

const normalSpToSelectableSp = sp => ({
  value: sp.hash,
  label: getScientificName(sp),
  species: sp,
  isDisabled: sp.disabled,
});

const boldMatches = (str, query, classes) => {
  const regEx = new RegExp(`(${escapeRegExp(query)})`, 'i');
  const strSplitted = str.split(regEx);
  const strFormatted = strSplitted.map((piece, index) => {
    if (index % 2 === 1) {
      return <strong key={ index } className={ classes.match }>{ piece }</strong>;
    }
    return piece;
  });
  return strFormatted;
};

const SpeciesInMenu = ({ species, query }) => {
  const classes = useStyles();

  const speciesName = getScientificName(species);
  const speciesSynonym = species.clean_synonym || '';
  const matchInName = speciesName.toLowerCase().includes(query);

  return matchInName ?
    <Tooltip title={ speciesSynonym } placement="left">
      <Box className={ classes.speciesName }>{ boldMatches(speciesName, query, classes) }</Box>
    </Tooltip> :
    <Tooltip title={ boldMatches(speciesSynonym, query, classes) } placement="left">
      <Box className={ classes.speciesName }>{ speciesName }<strong> (s)</strong></Box>
    </Tooltip>;
};

SpeciesInMenu.propTypes = {
  species: PropTypes.object,
  query: PropTypes.string,
};

const AsyncSpeciesSelector = ({ searchController, onSelect, maxResults, optionsFilter, ...props }) => {
  const { selectedSourceId } = useContext(SourcesContext);

  const searchSpecies = async querySearch => {
    const query = {
      querySearch: querySearch.toLowerCase().trim(),
      limit: maxResults,
      sourceId: selectedSourceId,
    };
    const speciesFound = await searchController(query);
    if (optionsFilter) {
      // if a filtering function was given, filter out species according to that function:
      return speciesFound.reduce((acc, sp) => {
        if (!optionsFilter(sp)) {
          return acc;
        } else {
          acc.push(normalSpToSelectableSp(sp));
          return acc;
        }
      }, []);
    }
    return speciesFound.map(normalSpToSelectableSp);
  };

  return <AsyncSelect
    autoFocus
    noOptionsMessage={ ({ inputValue }) => inputValue ? 'Sin resultados' : 'Escribe un nombre de especie' }
    loadingMessage={ () => 'Buscando...' }
    loadOptions={ searchSpecies }
    styles={{ menuPortal: base => ({ ...base, zIndex: 1301 }) }} // default dialog z-index is 1300... for some reason
    menuPortalTarget={ document.body }
    onChange={ optionSelected => onSelect(optionSelected.species) }
    placeholder='Escribe para filtrar especies'
    formatOptionLabel={ (currentOption, { context, inputValue }) =>
      <SpeciesInMenu species={ currentOption.species } query={ context === 'menu' ? inputValue.toLowerCase().trim() : '' } />
    }
    { ...props }
  />;
};

AsyncSpeciesSelector.defaultProps = {
  maxResults: 10,
};

AsyncSpeciesSelector.propTypes = {
  onSelect: PropTypes.func,
  searchController: PropTypes.func,
  maxResults: PropTypes.number,
  optionsFilter: PropTypes.func,
};

export { AsyncSpeciesSelector };