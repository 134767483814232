import { get, post, patch, del } from 'src/services/api';

const getSources = async () => (await get('flora/sources')).data;

const getCatalogs = async () => (await get('flora/catalogs')).data;

const getCatalogsInfo = async query => (await get('flora/catalogs-info', query)).data;

const getSourcesInCatalog = async catalogId => (await get(`flora/sources-in-catalog/${catalogId}`)).data;

const editCatalogDescription = async ({ catalogId, description }) =>
  (await patch(`flora/edit-catalog-description/${catalogId}`, { description })).data;

const getNewSpecies = async versionId => (await get('flora/new', { versionId })).data;

const getChangedSpecies = async versionId => (await get('flora/changed', { versionId })).data;

const getLostSpecies = async versionId => (await get('flora/lost', { versionId })).data;

const getRenamedSpecies = async versionId => (await get('flora/renamed', { versionId })).data;

const searchToRename = async params => (await get('/flora/rename-search', params)).data;

const approveVersion = async body => (await post('/flora/approve-version', body)).data;

const getVersion = async (version, sourceId) =>
  (await get('/flora/get-version', { ...(version && { version }), sourceId })).data;

const renameSpecies = async body => (await post('/flora/rename', body)).data;

const deleteSpeciesRename = async id => (await del(`/flora/rename/${id}`)).data;

const getValidCategory = async speciesHash => (await get(`flora/valid-category/${speciesHash}`)).data;


export {
  getSources,
  getCatalogs,
  getCatalogsInfo,
  getSourcesInCatalog,
  editCatalogDescription,
  getNewSpecies,
  getChangedSpecies,
  getLostSpecies,
  getRenamedSpecies,
  searchToRename,
  approveVersion,
  getVersion,
  renameSpecies,
  deleteSpeciesRename,
  getValidCategory,
};
