import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { AppBar, Typography, Button, Select, MenuItem } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { userApi } from 'src/services';
import { getCurrentUser, cleanUserDate } from 'src/utils/utils';
import { CatalogsContext, SourcesContext } from 'src/contexts';


const useStyles = makeStyles(() => ({
  linkGoHome: {
    textDecoration: 'inherit',
    color: 'inherit',
  },
  title: {
    flexGrow: 1,
  },
  select: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  selectInput: {
    paddingTop: 0,
    paddingBottom: 4,
  },
}));

const CustomAppBar = ({ specietorType }) => {
  const classes = useStyles();
  const history = useHistory();
  const { firstName, lastName } = getCurrentUser();
  const { catalogs, selectedCatalogId, selectCatalog } = useContext(CatalogsContext);
  const { sources, selectedSourceId, selectSource } = useContext(SourcesContext);

  const logout = async () => {
    try {
      await userApi.logout();
      cleanUserDate();
      history.push('/');
    } catch (e) {
      console.error(e);
    }
  };

  const onChangeSource = e => {
    const sourceId = e.target.value;
    selectSource(sourceId);
  };

  const onChangeCatalog = e => {
    const catalogId = e.target.value;
    selectCatalog(catalogId);
  };

  const showSourceSelection = useMemo(() => {
    if (!sources || sources.length === 0) {
      return false;
    }
    return Boolean(sources.find(sour => sour.id === selectedSourceId));
  }
  , [ sources, selectedSourceId ]);

  const showCatalogSelection = useMemo(() => {
    if (!catalogs || catalogs.length === 0) {
      return false;
    }
    return Boolean(catalogs.find(cat => cat.id === selectedCatalogId));
  }
  , [ catalogs, selectedCatalogId ]);

  return (
    <AppBar position="fixed">
      <Toolbar>
        <Typography variant="h6" noWrap className={ classes.title }>
          <Link to='/home' className={ classes.linkGoHome }>
            Specietor
          </Link>
          { specietorType && <span> / { specietorType }</span> }
          { showCatalogSelection &&
            <>
              <span style={{ marginRight: 10 }}> / <label id='catalog-selector-label'>Catálogo</label>:</span>
              <Select
                labelId='catalog-selector-label'
                className={ classes.select }
                value={ selectedCatalogId }
                onChange={ onChangeCatalog }
                inputProps={{
                  className: classes.selectInput,
                }}
                displayEmpty
              >
                { catalogs.map(o => <MenuItem key={ o.id } value={ o.id }>{ o.display_name }</MenuItem>) }
              </Select>
              <span style={{ marginRight: 10 }}> / <label id='source-selector-label'>Fuente</label>:</span>
              {/* TODO: write catalog version here when that's ready? */}
              { showSourceSelection ?
                <>
                  <Select
                    labelId='source-selector-label'
                    className={ classes.select }
                    value={ selectedSourceId }
                    onChange={ onChangeSource }
                    inputProps={{
                      className: classes.selectInput,
                    }}
                    displayEmpty
                  >
                    { sources.map(o => <MenuItem key={ o.id } value={ o.id }>{ o.display_name }</MenuItem>) }
                  </Select>
                </> :
                <span>No tiene fuentes</span>
              }
            </>
          }
        </Typography>
        <Typography noWrap>Bienvenido: { firstName } { lastName } |</Typography>
        <Button color="inherit" onClick={ logout }>Cerrar Sesión</Button>
      </Toolbar>
    </AppBar>
  );
};

CustomAppBar.propTypes = {
  specietorType: PropTypes.string,
};

export { CustomAppBar };
