import React from 'react';
import PropTypes from 'prop-types';

import { CatalogsContextProvider } from 'src/contexts/CatalogsContext';
import { SourcesContextProvider } from 'src/contexts/SourcesContext';
import { AlertsContextProvider } from 'src/contexts/AlertsContext';


export const FloraFaunaContextProvider = ({ children }) => {
  return (
    <CatalogsContextProvider>
      <SourcesContextProvider>
        <AlertsContextProvider>
          { children }
        </AlertsContextProvider>
      </SourcesContextProvider>
    </CatalogsContextProvider>
  );
};


FloraFaunaContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
