import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';

import { Home, Flora, Fauna } from 'src/layouts';
import { LoginContainer } from 'src/pages';
import theme from 'src/theme';
import { FloraFaunaContextProvider } from 'src/contexts';

const ProvidedFlora = () => {
  return (
    <FloraFaunaContextProvider>
      <Flora/>
    </FloraFaunaContextProvider>
  );
};


const ProvidedFauna = () => {
  return (
    <FloraFaunaContextProvider>
      <Fauna/>
    </FloraFaunaContextProvider>
  );
};


class App extends Component {
  render() {
    return (
      <ThemeProvider theme={ theme }>
        <Router>
          <Switch>
            <Route path="/" exact component={ LoginContainer }/>

            <Route path="/home" exact component={ Home } />
            <Route path="/flora" component={ ProvidedFlora } />
            <Route path="/fauna" component={ ProvidedFauna } />
          </Switch>
        </Router>
      </ThemeProvider>
    );
  }
}


export default App;
