import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { AsyncSpeciesSelector } from 'src/components/inputs';
import { utils } from 'src/utils';


const AssociationDialog = ({ open, onCloseDialog, onConfirm, ds68Species, searchController }) => {
  const [ speciesSelected, setSpeciesSelected ] = useState();
  const realClose = () => {
    onCloseDialog();
    setSpeciesSelected();
  };

  return <Dialog open={ open } onClose={ realClose } aria-labelledby="ds68-association-modal-title">
    <DialogTitle id="ds68-association-modal-title">
      Asociar a { '"' }<Box component="span" fontStyle="italic">{ utils.upperFirst(ds68Species.scientificName || '') }</Box>{ '"' }
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        Usa el buscador para encontrar la especie a la cual quieres vincular esta especie del <strong>DS68</strong>.<br/>
        Las especies que ya tienen asociada otra en DS68 estarán <strong>deshabilitadas</strong>, debes des-asociarlas
        antes si quieres volver a asociarlas.<br/>
        La búsqueda se efectúa dentro del nombre científico y sinonimias disponibles.
      </DialogContentText>
      <AsyncSpeciesSelector maxResults={ 30 } onSelect={ s => setSpeciesSelected(s) } searchController={ searchController } />
    </DialogContent>
    <DialogActions>
      <Button onClick={ realClose } color="primary">
        Cancelar
      </Button>
      <Button disabled={ !speciesSelected } onClick={ () => onConfirm(speciesSelected) } color="primary">
        Asociar
      </Button>
    </DialogActions>
  </Dialog>;
};

AssociationDialog.propTypes = {
  open: PropTypes.bool,
  onCloseDialog: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  ds68Species: PropTypes.object,
  searchController: PropTypes.func.isRequired,
};

export { AssociationDialog };