import React, { useState, useEffect } from 'react';
import { Paper, Grid, Box, Typography, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { userApi } from 'src/services';
import { setCurrentUser } from 'src/utils/utils';


const loginFormStyles = makeStyles(theme => ({
  errorLabel: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  errorMsg: {
    color: '#6c6c6c',
  },
}));

const LoginContainer = props => {
  const classes = loginFormStyles();
  const history = props.history;

  const [ state, setState ] = useState({ email: '', password: '', errorMsg: false });

  const redirectToInitialPage = () => history.push('home');

  useEffect(() => {
    const fn = async () => {
      try {
        const isAuthenticated = await userApi.isAuthenticated();
        if (isAuthenticated) {
          redirectToInitialPage();
        }
      } catch (error) {
        if (error.httpStatus !== 401) {
          console.log(error);
          throw error;
        }
        // falló isAuthenticated, que es lo normal si no has hecho log in. Hacer nada y absorber el throw
      }
    };
    fn();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    try {
      const { email, password } = state;
      const resp = await userApi.login({ email, password });
      if (resp) {
        setCurrentUser(resp.user);
        redirectToInitialPage();
      } else {
        setState({ ...state, errorMsg: 'Usuario y/o contraseña inválidos.' });
      }
    } catch (e) {
      if (e.serverMessage) {
        setState({ ...state, errorMsg: e.serverMessage });
      } else {
        console.error(`Problem with login: ${e}`);
      }
    }
  };

  const isEnter = e => e.key === 'Enter';

  const handleChange = e => setState({ ...state, [e.target.name]: e.target.value, errorMsg: false });

  const { password, email, errorMsg } = state;
  return (
    <Grid container alignItems="center" justifyContent="center" style={ { height: '100vh' } }>
      <Grid md={ 4 } item>
        <Paper elevation={ 7 } style={ { paddingLeft: 20, paddingRight: 20 }}>
          <Grid container direction="column" alignItems="center" justifyContent="center" onKeyDown={e => isEnter(e) && handleSubmit()}>
            <Box mt={3}>
              <Typography variant="h6" color="primary">INICIAR SESIÓN</Typography>
            </Box>
            <Box mt={3}>
              <TextField autoFocus required name="email" value={email} onChange={handleChange} label="Correo" fullWidth />
              <TextField required value={password} name="password" type="password" onChange={handleChange} label="Contraseña" fullWidth />
            </Box>
            <Box mt={3}>
              <Button color="primary" variant="contained" onClick={handleSubmit}>Entrar</Button>
            </Box>
            <Box my={2}>
              {errorMsg &&
                <Box display="flex" flexDirection="column" alignItems="center" >
                  <Typography className={classes.errorLabel} align="center">HA OCURRIDO UN ERROR</Typography>
                  <Typography className={classes.errorMsg} align="center">{errorMsg}</Typography>
                </Box>
              }
            </Box>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

LoginContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};


export {
  LoginContainer,
};