import React from 'react';
import PropTypes from 'prop-types';

import { floraApi } from 'src/services';
import { SpeciesUpdateContainer } from 'src/components/containers';


const FloraSpeciesUpdateContainer = ({ sourceId }) => <SpeciesUpdateContainer api={ floraApi } sourceId={ sourceId } />;

FloraSpeciesUpdateContainer.propTypes = {
  sourceId: PropTypes.string,
};

export { FloraSpeciesUpdateContainer };
