import { get, patch } from 'src/services/api';


const getAssociations = async query => (await get(`ds68`, query)).data;

const getCounterWithoutAssociation = async query => (await get(`ds68/without-association-counter`, query)).data;

const associateSpecies = async params => (await patch(`ds68/associate`, params)).data;

const desassociateSpecies = async params => (await patch(`ds68/desassociate`, params)).data;

const search = async query => (await get(`ds68/search`, query)).data;

const checkIfSpeciesIsInDs68 = async hash => (await get(`ds68/check/${hash}`)).data;


const ds68Api = {
  getAssociations,
  getCounterWithoutAssociation,
  associateSpecies,
  desassociateSpecies,
  search,
  checkIfSpeciesIsInDs68,
};

export { ds68Api };
