import { get, post, patch, del } from 'src/services/api';

const getSources = async () => (await get('fauna/sources')).data;

const getCatalogs = async () => (await get('fauna/catalogs')).data;

const getCatalogsInfo = async query => (await get('fauna/catalogs-info', query)).data;

const getSourcesInCatalog = async catalogId => (await get(`fauna/sources-in-catalog/${catalogId}`)).data;

const editCatalogDescription = async ({ catalogId, description }) =>
  (await patch(`fauna/edit-catalog-description/${catalogId}`, { description })).data;

const getNewSpecies = async versionId => (await get('fauna/new', { versionId })).data;

const getChangedSpecies = async versionId => (await get('fauna/changed', { versionId })).data;

const getLostSpecies = async versionId => (await get('fauna/lost', { versionId })).data;

const getRenamedSpecies = async versionId => (await get('fauna/renamed', { versionId })).data;

const searchToRename = async params => (await get('/fauna/rename-search', params)).data;

const approveVersion = async body => (await post('/fauna/approve-version', body)).data;

const getVersion = async (version, sourceId) =>
  (await get('/fauna/get-version', { ...(version && { version }), sourceId })).data;

const renameSpecies = async body => (await post('/fauna/rename', body)).data;

const deleteSpeciesRename = async id => (await del(`/fauna/rename/${id}`)).data;

const getValidCategory = async speciesHash => (await get(`fauna/valid-category/${speciesHash}`)).data;


export {
  getSources,
  getCatalogs,
  getCatalogsInfo,
  getSourcesInCatalog,
  editCatalogDescription,
  getNewSpecies,
  getChangedSpecies,
  getLostSpecies,
  getRenamedSpecies,
  searchToRename,
  approveVersion,
  getVersion,
  renameSpecies,
  deleteSpeciesRename,
  getValidCategory,
};
