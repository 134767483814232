import React, { useEffect, useState, Fragment, useCallback } from 'react';
import { Grid, Typography, Button, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { NewSpeciesList } from 'src/components/containers/speciesUpdate/components/NewSpeciesList';
import { ChangedSpeciesList } from 'src/components/containers/speciesUpdate/components/ChangedSpeciesList';
import { LostSpeciesList } from 'src/components/containers/speciesUpdate/components/LostSpeciesList';
import { RenamedSpeciesList } from 'src/components/containers/speciesUpdate/components/RenamedSpeciesList';
import { SpeciesRenameSearcher } from 'src/components/containers/speciesUpdate/components/SpeciesRenameSearcher';
import { ApproveVersionDialog } from 'src/components/containers/speciesUpdate/components/ApproveVersionDialog';
import { getCurrentUser, formatDate, formatDateExtension } from 'src/utils/utils';
import { useHistory } from 'react-router-dom';


const SpeciesUpdateContainer = ({ api, sourceId }) => {
  const [ newSpeciesList, setNewSpeciesList ] = useState([]);
  const [ changedSpecies, setChangedSpecies ] = useState([]);
  const [ lostSpecies, setLostSpecies ] = useState([]);
  const [ renamedSpecies, setRenamedSpecies ] = useState([]);
  const [ listVersion, setListVersion ] = useState({});
  const [ versionModalIsOpen, setVersionModalIsOpen ] = useState(false);
  const [ speciesModalIsOpen, setSpeciesModalIsOpen ] = useState(false);
  const [ speciesToRename, setSpeciesToRename ] = useState({});
  const [ loading, setLoading ] = useState(false);

  const history = useHistory();
  const userIsAdmin = getCurrentUser().role === 'admin';


  useEffect(() => {
    const getAllSpecies = async () => {
      setLoading(true);
      const version = parseInt(new URLSearchParams(history.location.search).get('version'), 10);
      const listVersion = await api.getVersion(version, sourceId) || { id: 0, version: version ? version : 0, approved: true };
      setListVersion(listVersion);
      const [ newSpecies, lostSpecies, renamedSpecies, changedSpecies ] = await Promise.all([
        api.getNewSpecies(listVersion.id),
        api.getLostSpecies(listVersion.id),
        api.getRenamedSpecies(listVersion.id),
        api.getChangedSpecies(listVersion.id),
      ]);

      setNewSpeciesList(newSpecies);
      setLostSpecies(lostSpecies);
      setRenamedSpecies(renamedSpecies);
      setChangedSpecies(changedSpecies);
      setLoading(false);
    };

    getAllSpecies();
    return () => {
      setLoading(true);
    };
    // eslint-disable-next-line
  }, [ sourceId ]);

  const onClickInSearch = useCallback(sp => {
    setSpeciesToRename(sp);
    setSpeciesModalIsOpen(true);
  }, [ setSpeciesToRename, setSpeciesModalIsOpen ]);

  const renameSpecies = async ({ newSpecies, preserveOldCC }) => {
    const resp = await api.renameSpecies({
      preserve_old_cc: preserveOldCC,
      fk_old_hash: speciesToRename.hash,
      fk_new_hash: newSpecies.hash,
      fk_version_id: listVersion.id,
    });

    setLostSpecies(lostSpecies.map(s =>
      s.hash === speciesToRename.hash ? { ...s, rename_targets: [ ...s.rename_targets, newSpecies.hash ] } : s,
    ));

    setNewSpeciesList(newSpeciesList.filter(s => s.hash !== newSpecies.hash));

    const newRenamedSpecies = { id: resp.id, oldSpecies: speciesToRename, newSpecies };
    setRenamedSpecies([ ...renamedSpecies, newRenamedSpecies ]);

    setSpeciesModalIsOpen(false);
  };

  const deleteRename = useCallback(async ({ id, oldSpecies, newSpecies }) => {
    await api.deleteSpeciesRename(id);

    const newSpeciesRenamed = renamedSpecies.filter(rs => rs.id !== id);

    setLostSpecies(lostSpecies.map(s =>
      s.hash === oldSpecies.hash ?
        { ...s, rename_targets: s.rename_targets.filter(shash => shash !== newSpecies.hash) }
        : s,
    ));
    setRenamedSpecies(newSpeciesRenamed);

    if (newSpeciesRenamed.every(rs => rs.newSpecies.hash !== newSpecies.hash) && newSpecies.state === 'new') {
      setNewSpeciesList([ ...newSpeciesList, newSpecies ]);
    }
  }, [ lostSpecies, newSpeciesList, renamedSpecies, api ]);

  const approveVersion = async () => {
    await api.approveVersion({ versionId: listVersion.id });
    setVersionModalIsOpen(false);
    setListVersion({ ...listVersion, approved: true });
  };

  return (
    <Fragment>
      <Grid container spacing={ 2 }>
        <Grid container justifyContent="space-between" item xs={ 12 }>
          <Box>
            <Typography variant="h6" display="inline">
              Versión actual: { listVersion.version }
            </Typography>
            { listVersion.created_at &&
              <Typography style={{ marginLeft: '0.5rem' }} variant="subtitle1" display="inline">
                { formatDate(listVersion.created_at) } { formatDateExtension(listVersion.created_at) }
              </Typography>
            }
            {loading &&
              <Typography style={{ marginLeft: '1rem' }} variant="h6" display="inline">
                <em>Cargando...</em>
              </Typography>
            }
          </Box>
          { userIsAdmin &&
            <Button disabled={ listVersion.approved } color="primary" variant="contained" onClick={ () => setVersionModalIsOpen(true) }>
              { listVersion.approved ? 'Aprobada' : 'Aprobar' }
            </Button>
          }
        </Grid>
        <Grid item xs={ 12 }>
          <ChangedSpeciesList changedSpecies={ changedSpecies }/>
        </Grid>
        <Grid item md={ 3 } lg={ 4 }>
          <LostSpeciesList userIsAdmin={ userIsAdmin }
            lostSpecies={ lostSpecies } onClickInSearch={ listVersion.approved ? undefined : onClickInSearch }
          />
        </Grid>
        <Grid item md={ 6 } lg={ 4 }>
          <RenamedSpeciesList userIsAdmin={ userIsAdmin }
            renamedSpecies={ renamedSpecies } onClickInClear={ listVersion.approved ? undefined : deleteRename }
          />
        </Grid>
        <Grid item md={ 3 } lg={ 4 }>
          <NewSpeciesList newSpecies={ newSpeciesList } />
        </Grid>
      </Grid>
      <SpeciesRenameSearcher open={ speciesModalIsOpen }
        speciesToRename={ speciesToRename }
        onCloseDialog={ () => setSpeciesModalIsOpen(false) }
        onConfirm={ renameSpecies }
        api={ api }
      />
      <ApproveVersionDialog open={ versionModalIsOpen }
        onCloseDialog={ () => setVersionModalIsOpen(false) }
        onConfirm={ approveVersion }
        version={ listVersion.version }
      />
    </Fragment>
  );
};

SpeciesUpdateContainer.propTypes = {
  sourceId: PropTypes.string,
  api: PropTypes.shape({
    getVersion: PropTypes.func.isRequired,
    getNewSpecies: PropTypes.func.isRequired,
    getChangedSpecies: PropTypes.func.isRequired,
    getLostSpecies: PropTypes.func.isRequired,
    getRenamedSpecies: PropTypes.func.isRequired,
    renameSpecies: PropTypes.func.isRequired,
    deleteSpeciesRename: PropTypes.func.isRequired,
    approveVersion: PropTypes.func.isRequired,
    getValidCategory: PropTypes.func.isRequired,
    searchToRename: PropTypes.func.isRequired,
  }),
};

export { SpeciesUpdateContainer };