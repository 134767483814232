const upperFirst = str => str.replace(/^(.)/, (m, m1) => m1.toUpperCase());

const setCurrentUser = user => localStorage.setItem('user', JSON.stringify(user));

const getCurrentUser = () => JSON.parse(localStorage.getItem('user') || '{}');

const cleanUserDate = () => localStorage.removeItem('user');

const _prependZero = n => (n < 10) ? `0${n}` : String(n);

const formatDate = dateString => {
  const date = new Date(dateString);
  const day = _prependZero(date.getDate());
  const month = _prependZero(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const formatDateExtension = (dateString, { withSecs = false } = {}) => {
  const date = new Date(dateString);
  const hour = _prependZero(date.getHours());
  const minutes = _prependZero(date.getMinutes());
  const seconds = _prependZero(date.getSeconds());
  const secsString = withSecs ? `:${seconds}` : '';
  return `${hour}:${minutes}${secsString}`;
};


export {
  upperFirst,
  setCurrentUser,
  getCurrentUser,
  cleanUserDate,
  formatDate,
  formatDateExtension,
};
