import { get, post } from 'src/services/api';


const login = async data => (await post('user/login', data)).data;

const logout = async data => (await post('user/logout', data)).data;

const isAuthenticated = async data => (await get('user/is-authenticated', data)).data.isAuthenticated;


export {
  login,
  logout,
  isAuthenticated,
};
