import React, { useMemo, useContext } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Zoom from '@material-ui/core/Zoom';
import MuiAlert from '@material-ui/lab/Alert';
import { Box, Drawer, CssBaseline, List, ListItem, ListItemText } from '@material-ui/core';

import { CustomAppBar } from 'src/layouts/components/CustomAppBar';
import { CatalogsContext, SourcesContext } from 'src/contexts';
import { makeUrl } from 'src/layouts/utils';


const useStyles = makeStyles({
  alerts: {
    paddingLeft: '3px',
    paddingRight: '3px',
    '& > *': {
      marginBottom: '3px',
    },
  },
});


const FloraFaunaLayout = ({ routes, name, alerts }) => {
  const history = useHistory();
  const currentPath = history.location.pathname;
  const classes = useStyles();
  const { sources, selectedSourceId } = useContext(SourcesContext);
  const { selectedCatalogId } = useContext(CatalogsContext);

  const source = useMemo(() => {
    if (!sources || sources.length === 0) {
      return undefined;
    }
    return sources.find(sour => sour.id === selectedSourceId);
  }
  , [ sources, selectedSourceId ]);

  const goToRoute = route => {
    const newUrl = makeUrl(route, selectedCatalogId, selectedSourceId);
    history.push(newUrl);
  };

  const isSelectedPage = route => {
    const routeUrl = makeUrl(route, selectedCatalogId, selectedSourceId);
    return currentPath === routeUrl;
  };

  return (
    <Box display="flex">
      <CssBaseline />
      <CustomAppBar specietorType={ name } />
      <Drawer variant="permanent">
        <Toolbar />
        <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
          <List>
            {
              routes.map((r, i) =>
                <ListItem key={ i } onClick={ () => goToRoute(r) } selected={ isSelectedPage(r) } button>
                  <ListItemText primary={ r.name } />
                </ListItem>,
              )
            }
          </List>
          <Box className={ classes.alerts }>
            { alerts.map((alert, i) =>
              <Zoom key={ i } in timeout={ { enter: 1000 + i * 500 } }>
                <MuiAlert variant="filled" severity={ alert.type }>{ alert.text }</MuiAlert>
              </Zoom>)
            }
          </Box>
        </Box>
      </Drawer>
      <Box flexGrow={ 1 } p={ 3 }>
        <Toolbar />
        <>
          { source ?
            <Switch>
              {
                routes.map((r, i) =>
                  <Route exact key={ i } path={ r.path }
                    render={ props =>
                      <r.component { ...props } sourceId={source.id} />
                    }
                  />,
                )
              }
            </Switch>
            : null // TODO: loading spinner or something?
          }
        </>
      </Box>
    </Box>
  );
};

FloraFaunaLayout.propTypes = {
  name: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  alerts: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    type: PropTypes.oneOf([ 'warning' ]),
  })),
};

export { FloraFaunaLayout };
