import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';


// Alerts
export const makeEeccCounterAlert = count => ({
  text: `Hay ${count} especies sin asociar en categoría de conservación`,
  type: 'warning',
  key: 'eeccCountAlert',
});

export const makeDsCounterAlert = count => ({
  text: `Hay ${count} especies sin asociar del DS68`,
  type: 'warning',
  key: 'ds68CountAlert',
});

// puts the new alert on top, having it replace any previous alert with which it shared its key value.
export const makeUpdatedAlerts = (prevAlerts, newAlert) => [ newAlert, ...prevAlerts.filter(ale => ale.key !== newAlert.key) ];


export const AlertsContext = React.createContext({});

export const AlertsContextProvider = ({ children }) => {
  const [ alerts, setAlerts ] = useState([]);

  const updateAlert = newAlert => {
    const newAlerts = makeUpdatedAlerts(alerts, newAlert);
    setAlerts(newAlerts);
  };

  const updateEeccCounterAlert = count => {
    updateAlert(makeEeccCounterAlert(count));
  };

  const updateDs68CounterAlert = count => {
    updateAlert(makeDsCounterAlert(count));
  };

  const alertsContextValue = useMemo(() => {
    return { alerts, setAlerts, updateAlert, updateEeccCounterAlert, updateDs68CounterAlert };
    // eslint-disable-next-line
  }, [ alerts ]);

  return (
    <AlertsContext.Provider value={ alertsContextValue }>
      { children }
    </AlertsContext.Provider>
  );
};

AlertsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
