import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { Box, CssBaseline, Typography, Grid, Paper } from '@material-ui/core';

import { LocalFlorist, Pets, MergeType } from '@material-ui/icons';

import { CustomAppBar } from 'src/layouts/components/CustomAppBar';
import { BigOrMiddleButton } from 'src/layouts/components/BigOrMiddleButton';
import { EeccMatcherDialog } from 'src/components';
import { userApi } from 'src/services';


const routes = [
  {
    name: 'Flora',
    path: '/flora',
    color: '#8BC63D',
    icon: LocalFlorist,
  },
  {
    name: 'Fauna',
    path: '/fauna',
    color: '#FBB03B',
    icon: Pets,
  },
];

const utilities = [
  {
    name: 'Eecc Matcher',
    color: '#ef5350',
    icon: MergeType,
    component: EeccMatcherDialog,
  },
];

const Home = props => {
  const history = props.history;
  const [ userIsAuthenticated, setUserIsAuthenticated ] = useState(false);

  const redirectToInitialPage = () => history.push('/');
  useEffect(() => {
    const fn = async () => {
      try {
        await userApi.isAuthenticated();
        setUserIsAuthenticated(true);
      } catch (e) {
        console.error(e);
        redirectToInitialPage();
      }
    };
    fn();
    // eslint-disable-next-line
  }, []);

  return !userIsAuthenticated ? <Box></Box> :
    <Box display="flex">
      <CssBaseline />
      <CustomAppBar />
      <Box flexGrow={ 1 } p={ 3 }>
        <Toolbar />
        <Paper>
          <Grid container spacing={ 2 } alignItems='center' justifyContent='center'>
            <Grid item xs={ 12 }>
              <Typography variant='h3' align='center'>Listados de Especies</Typography>
            </Grid>
            {
              routes.map((r, i) => <Grid key={ i } item>
                <BigOrMiddleButton
                  color={ r.color }
                  onClick={ () => history.push(r.path) }
                  icon={ r.icon }
                />
                <Typography variant='h5' align='center'>{ r.name }</Typography>
              </Grid>)
            }
          </Grid>
        </Paper>
        <Box mb={ 3 } />
        <Paper>
          <Grid container spacing={ 2 } alignItems='center' justifyContent='center'>
            <Grid item xs={ 12 }>
              <Typography variant='h3' align='center'>Utilidades</Typography>
            </Grid>
            {
              utilities.map((r, i) => <Grid key={ i } item>
                <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                  <r.component
                    customButton={ ({ defaultOnClick }) =>
                      <BigOrMiddleButton
                        color={ r.color }
                        onClick={ defaultOnClick }
                        icon={ r.icon }
                        middle
                      />
                    }
                  />
                  <Typography variant='h5' align='center'>{ r.name }</Typography>
                </Box>
              </Grid>)
            }
          </Grid>
        </Paper>
      </Box>
    </Box>;
};

Home.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export { Home };
