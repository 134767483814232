import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, List, ListItem, ListItemText, ListItemIcon, IconButton, Divider } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { useStyles } from 'src/components/styles';


const Species = ({ species, userIsAdmin, onClickInSearch }) => {
  const classes = useStyles();
  const renameCount = species.rename_targets.length;

  return (
    <ListItem>
      <ListItemText
        className={ classes.speciesName }
        primary={ species.scientific_name[0].toUpperCase() + species.scientific_name.slice(1) }
        secondary={ renameCount > 0 ? `${renameCount} renombre${renameCount > 1 ? 's' : ''}` : ''}
      />
      {
        userIsAdmin && onClickInSearch &&
          <ListItemIcon className={ classes.infoIconContainer }>
            <IconButton onClick={ () => onClickInSearch(species) }>
              <SearchIcon />
            </IconButton>
          </ListItemIcon>
      }
    </ListItem>
  );
};

Species.propTypes = {
  species: PropTypes.object,
  userIsAdmin: PropTypes.bool.isRequired,
  onClickInSearch: PropTypes.func,
};


const LostSpeciesList = ({ lostSpecies, onClickInSearch, userIsAdmin }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        title='Perdidas'
        subheader={ `${ lostSpecies.length } especies` }
      />
      <CardContent className={ classes.speciesListContainer }>
        <List dense>
          { lostSpecies
            .sort((a, b) => a.scientific_name > b.scientific_name ? 1 : -1)
            .map((sp, i) =>
              <Fragment key={ sp.hash }>
                <Species species={ sp } onClickInSearch={ onClickInSearch } userIsAdmin={ userIsAdmin } />
                { i !== lostSpecies.length - 1 && <Divider />}
              </Fragment>)
          }
        </List>
      </CardContent>
    </Card>
  );
};

LostSpeciesList.propTypes = {
  lostSpecies: PropTypes.array,
  userIsAdmin: PropTypes.bool.isRequired,
  onClickInSearch: PropTypes.func,
};

const Memoized = React.memo(LostSpeciesList);
export { Memoized as LostSpeciesList };
