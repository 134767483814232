import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardHeader, CardContent, List, ListItem, ListItemText, ListItemIcon, IconButton, Divider } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';

import { useStyles } from 'src/components/styles';
import { utils } from 'src/utils';


const SpeciesRenamed = ({ id, oldSpecies, newSpecies, userIsAdmin, onClickInClear }) => {
  const classes = useStyles();

  return (
    <ListItem>
      <ListItemText
        primary={ <Box component='span' className={ classes.speciesName }>{ utils.upperFirst(oldSpecies.scientific_name) }</Box> }
        secondary={
          <Box component='span'>
            es ahora
            <Box className={ classes.speciesName } component='span'>
              <strong> { utils.upperFirst(newSpecies.scientific_name) }</strong>
            </Box>
          </Box> }
      />
      {
        userIsAdmin && onClickInClear &&
          <ListItemIcon className={ classes.infoIconContainer }>
            <IconButton onClick={ () => onClickInClear({ id, oldSpecies, newSpecies }) }>
              <ClearIcon color='error' />
            </IconButton>
          </ListItemIcon>
      }
    </ListItem>
  );
};

SpeciesRenamed.propTypes = {
  id: PropTypes.string,
  oldSpecies: PropTypes.object,
  newSpecies: PropTypes.object,
  userIsAdmin: PropTypes.bool.isRequired,
  onClickInClear: PropTypes.func,
};


const RenamedSpeciesList = ({ renamedSpecies, userIsAdmin, onClickInClear }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        title='Renombradas'
        subheader={ `${ renamedSpecies.length } renombres` }
      />
      <CardContent className={ classes.speciesListContainer }>
        <List dense>
          { renamedSpecies
            .sort((a, b) => a.oldSpecies.scientific_name > b.oldSpecies.scientific_name ? 1 : -1)
            .map((data, i) =>
              <Fragment key={ data.id }>
                <SpeciesRenamed { ...data } onClickInClear={ onClickInClear } userIsAdmin={ userIsAdmin } />
                { i !== renamedSpecies.length - 1 && <Divider />}
              </Fragment>)
          }
        </List>
      </CardContent>
    </Card>
  );
};

RenamedSpeciesList.propTypes = {
  renamedSpecies: PropTypes.array,
  userIsAdmin: PropTypes.bool.isRequired,
  onClickInClear: PropTypes.func,
};

const Memoized = React.memo(RenamedSpeciesList);
export { Memoized as RenamedSpeciesList };
