import urlJoin from 'url-join';

import config from 'src/config/local';
const { serverUrl } = config;

const notRedirectUrls = [ 'user/login', 'user/is-authenticated' ];

const request = async ({ url, method = 'POST', data = {}, options = {} }) => {
  try {
    const finalUrl = new URL(url.match(/^https?:\/\//) ? url : urlJoin(serverUrl, 'api', url));
    const requestOptions = {
      method,
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      credentials: 'include',
      ...options,
    };

    if (method.toLowerCase() === 'get') {
      finalUrl.search = new URLSearchParams(data);
    } else {
      if (requestOptions.headers['Content-Type'] === 'multipart/form-data') {
        requestOptions.body = data;
        delete requestOptions.headers['Content-Type'];
      } else {
        requestOptions.body = JSON.stringify(data);
      }
    }
    const resp = await fetch(finalUrl, requestOptions);

    const contentType = resp.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      const jsonResp = await resp.json();
      if (200 <= resp.status && resp.status < 300) {
        return { ...jsonResp, httpStatus: resp.status };
      } else if (resp.status === 401 && notRedirectUrls.includes(url)) {
        return Promise.reject({ serverMessage: jsonResp.message, httpStatus: resp.status });
      } else if (resp.status === 401 || resp.status === 404) {
        window.location.href = '/';
        return;
      } else {
        return Promise.reject({ serverMessage: jsonResp.message, httpStatus: resp.status });
      }
    } else if (contentType && contentType.includes('text/csv')) {
      return await resp.text();
    } else {
      const textResp = await resp.text();
      console.log(textResp);
      return Promise.reject({ message: 'Error en el tipo de datos esperado' });
    }
  } catch (e) {
    console.log(e);
    return Promise.reject({ message: 'Error durante la petición de datos' });
  }
};

const get = (url, data, options) => request({ url, method: 'GET', data, options });
const post = (url, data, options) => request({ url, method: 'POST', data, options });
const patch = (url, data, options) => request({ url, method: 'PATCH', data, options });
const del = (url, data, options) => request({ url, method: 'DELETE', data, options });


export {
  get,
  post,
  patch,
  del,
};
