import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, List, ListItem, ListItemText, ListItemIcon, Tooltip, Divider } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { useStyles } from 'src/components/styles';
import { utils } from 'src/utils';


const Species = ({ species }) => {
  const classes = useStyles();
  const synonyms = species.synonyms || species.clean_synonym;
  return (
    <ListItem>
      <ListItemText
        className={ classes.speciesName }
        primary={ utils.upperFirst(species.scientific_name) }
      />
      { synonyms &&
        <ListItemIcon className={ classes.infoIconContainer }>
          <Tooltip color='disabled' title={ synonyms } className={ classes.tooltipCursor } >
            <InfoIcon/>
          </Tooltip>
        </ListItemIcon>
      }
    </ListItem>
  );
};

Species.propTypes = {
  species: PropTypes.object,
};


const NewSpeciesList = ({ newSpecies }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        title='Nuevas'
        subheader={ `${ newSpecies.length } especies` }
      />
      <CardContent className={ classes.speciesListContainer }>
        <List dense>
          { newSpecies
            .sort((a, b) => a.scientific_name > b.scientific_name ? 1 : -1)
            .map((sp, i) =>
              <Fragment key={ sp.hash }>
                <Species species={ sp } />
                { i !== newSpecies.length - 1 && <Divider />}
              </Fragment>)
          }
        </List>
      </CardContent>
    </Card>
  );
};

NewSpeciesList.propTypes = {
  newSpecies: PropTypes.array,
};

const Memoized = React.memo(NewSpeciesList);
export { Memoized as NewSpeciesList };
