import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  middleButton: {
    height: 100,
    width: 100,
    borderRadius: 15,
    backgroundColor: props => props.color,
  },
  bigButton: {
    height: 200,
    width: 200,
    borderRadius: 20,
    backgroundColor: props => props.color,
  },
  bigIcon: {
    width: 'inherit',
    height: 'fit-content',
    color: 'white',
  },
});

const BigOrMiddleButton = ({ color, icon: Icon, middle = false, ...props }) => {
  const classes = useStyles({ color });
  return <Button variant="contained" className={ middle ? classes.middleButton : classes.bigButton } { ...props }>
    <Icon className={ classes.bigIcon } />
  </Button>;
};

BigOrMiddleButton.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.object,
  middle: PropTypes.bool,
};


export { BigOrMiddleButton };
