import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography, InputBase, IconButton, Tooltip, Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

import { Ds68SpeciesCard } from 'src/pages/flora/ds68/components/Ds68SpeciesCard';
import { AssociationDialog } from 'src/pages/flora/ds68/components/AssociationDialog';
import { ds68Api } from 'src/services';
import { getCurrentUser } from 'src/utils/utils';
import { AlertsContext } from 'src/contexts';

const Ds68Container = ({ sourceId }) => {
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ ds68Species, setDs68Species ] = useState([]);
  const [ modalIsOpen, setModalIsOpen ] = useState(false);
  const [ ds68SpeciesSelected, setDs68SpeciesSelected ] = useState({});
  const [ textFilter, setTextFilter ] = useState('');
  const [ loadingInfo, setLoadingInfo ] = useState(true);
  const { updateDs68CounterAlert } = useContext(AlertsContext);

  const userIsAdmin = getCurrentUser().role === 'admin';

  useEffect(() => {
    const loadInfo = async () => {
      setLoadingInfo(true);
      const ds68Species = await ds68Api.getAssociations({ sourceId });
      setDs68Species(ds68Species);
      setLoadingInfo(false);
    };
    loadInfo();
    // eslint-disable-next-line
  }, [ sourceId ]);

  useEffect(() => {
    if (loadingInfo) {
      return;
    }
    const count = ds68Species.filter(sp => sp.associatedSpeciesList.length === 0).length;
    updateDs68CounterAlert(count);
    // eslint-disable-next-line
  }, [ ds68Species ]);


  const openModal = sp => {
    setDs68SpeciesSelected(sp);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setDs68SpeciesSelected({});
  };

  const associateSpecies = async sp => {
    const newAssociatedSpecies = await ds68Api.associateSpecies({
      ds68Id: ds68SpeciesSelected.id,
      speciesHash: sp.hash,
    });

    setDs68Species(ds68Species.map(ds => ds.id === ds68SpeciesSelected.id ?
      { ...ds, associatedSpeciesList: [ ...ds.associatedSpeciesList, newAssociatedSpecies ] } : ds));
    closeModal();
  };

  const desassociateSpecies = async ({ ds68Id, speciesHash }) => {
    const speciesRemovedHash = await ds68Api.desassociateSpecies({ speciesHash });

    setDs68Species(ds68Species.map(ds => ds.id === ds68Id ?
      { ...ds, associatedSpeciesList: ds.associatedSpeciesList.filter(ds => ds.hash !== speciesRemovedHash) } : ds));
    closeModal();
  };

  const onInputChange = text => {
    setTextFilter(text.toLowerCase().trim());
    setCurrentPage(1);
  };

  // filtro de especies
  const ds68SpeciesFiltered =
    textFilter === 'no asociadas' ? ds68Species.filter(ds => ds.associatedSpeciesList.length === 0)
      : textFilter === 'asociadas' ? ds68Species.filter(ds => ds.associatedSpeciesList.length > 0)
        : ds68Species.filter(ds =>
          ds.scientificName.toLowerCase().includes(textFilter) ||
          ds.associatedSpeciesList.some(es => es.scientificName.includes(textFilter)),
        );

  // page variables
  const pageSize = 48;
  const totalPages = Math.ceil(ds68SpeciesFiltered.length / pageSize);
  const initialItemIndex = (currentPage - 1) * pageSize;

  return (<>{
    loadingInfo || (!loadingInfo && ds68Species?.length) ?
      <>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Typography variant="h6" display="inline">
              Aquí puedes ver todas las asociaciones realizadas entre el DS68 y nuestras fuentes
            </Typography>
          </Grid>
          { loadingInfo ?
            <Typography variant="h6" display="inline">
              Cargando...
            </Typography> :
            <>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={ 3 }>
                </Grid>
                <Grid item>
                  <Pagination count={ totalPages } page={ currentPage } onChange={ (e, page) => setCurrentPage(page) } />
                </Grid>
                <Grid item xs={ 3 }>
                  <InputBase
                    placeholder="Filtra..."
                    inputProps={{ 'aria-label': 'Filtra...' }}
                    onChange={ e => onInputChange(e.target.value) }
                  />
                  <Tooltip
                    title={
                      <Box fontSize={ 12 }>
                        - Escribe {'"'}<strong>no asociadas</strong>{'"'} para ver las especies sin asociación<br/>
                        - Escribe {'"'}<strong>asociadas</strong>{'"'} para ver las especies asociadas<br/>
                      </Box>
                    }
                  >
                    <IconButton aria-label="search"><SearchIcon /></IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              { ds68SpeciesFiltered.slice(initialItemIndex, initialItemIndex + pageSize).map((ds, i) =>
                <Grid key={ ds.id } item md={ 6 } lg={ 4 }>
                  <Ds68SpeciesCard { ...{ ...ds, index: initialItemIndex + i } }
                    onClickInButton={ openModal } onClickInRemove={ desassociateSpecies } userIsAdmin={ userIsAdmin }
                  />
                </Grid>)
              }
              <Grid container justifyContent="center">
                <Pagination count={ totalPages } page={ currentPage } onChange={ (e, page) => setCurrentPage(page) } />
              </Grid>
            </>
          }
        </Grid>
        <AssociationDialog open={ modalIsOpen } ds68Species={ ds68SpeciesSelected }
          onConfirm={ associateSpecies }
          onCloseDialog={ () => setModalIsOpen(false) }
          searchController={ ds68Api.search }
        />
      </>
      : <Box><Typography variant="h6" display="inline">Esta fuente no tiene especies asociables a DS68</Typography></Box>
  }</>);
};

Ds68Container.propTypes = {
  sourceId: PropTypes.string,
};


export { Ds68Container };
