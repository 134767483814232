import { get, patch, post } from 'src/services/api';


const getAssociations = type => async query => (await get(`eecc/${type}/association`, query)).data;

const getCounterWithoutAssociation = type => async query => (await get(`eecc/${type}/without-association-counter`, query)).data;

const associateSpecies = type => async params => (await patch(`eecc/${type}/associate`, params)).data;

const desassociateSpecies = type => async params => (await patch(`eecc/${type}/desassociate`, params)).data;

const matchSpecies = async data => (await post('eecc/species-matcher', data));

const search = type => async query => (await get(`eecc/${type}/search`, query)).data;


const eeccApi = {
  matchSpecies,
  flora: {
    getAssociations: getAssociations('flora'),
    getCounterWithoutAssociation: getCounterWithoutAssociation('flora'),
    associateSpecies: associateSpecies('flora'),
    desassociateSpecies: desassociateSpecies('flora'),
    search: search('flora'),
  },
  fauna: {
    getAssociations: getAssociations('fauna'),
    getCounterWithoutAssociation: getCounterWithoutAssociation('fauna'),
    associateSpecies: associateSpecies('fauna'),
    desassociateSpecies: desassociateSpecies('fauna'),
    search: search('fauna'),
  },
};

export { eeccApi };
