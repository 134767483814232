import React from 'react';
import PropTypes from 'prop-types';

import { EeccContainer } from 'src/components/containers';


const FaunaEeccContainer = ({ sourceId }) => <EeccContainer eeccApiType='fauna' sourceId={ sourceId } />;

FaunaEeccContainer.propTypes = {
  sourceId: PropTypes.string,
};

export { FaunaEeccContainer };
