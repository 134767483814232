import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles(() => ({
  tooltipCursor: {
    cursor: 'pointer',
  },
  infoIconContainer: {
    minWidth: 0,
  },
  speciesListContainer: {
    maxHeight: 'calc(100vh - 316px)',
    overflowY: 'auto',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  speciesName: {
    fontStyle: 'italic',
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
}));
