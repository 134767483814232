import React, { useMemo } from 'react';
import PropTypes from 'prop-types';


export const CatalogsContext = React.createContext({});

const initialCatalogsState = {
  catalogs: [],
  selectedCatalogId: null,
};

const catalogsReducer = (state, action) => {
  switch (action.type) {
    case 'select': {
      return { ...state, selectedCatalogId: action.payload };
    }
    case 'setCatalogs': {
      const { newCatalogs, selectedCatalogId } = action.payload;

      // Let's default to selecting the first one (the backend returns them ordered by display_name)
      const newSelected = selectedCatalogId !== undefined ? selectedCatalogId : (newCatalogs.length ? newCatalogs[0].id : null);
      return { catalogs: newCatalogs, selectedCatalogId: newSelected };
    }
    case 'setDefault': {
      return initialCatalogsState;
    }
    default:
      return state;
  }
};

export const CatalogsContextProvider = ({ children }) => {
  const [ state, dispatch ] = React.useReducer(catalogsReducer, initialCatalogsState);

  const selectCatalog = CatalogId => {
    dispatch({ type: 'select', payload: CatalogId });
  };

  const setCatalogs = ({ newCatalogs, selectedCatalogId }) => {
    dispatch({ type: 'setCatalogs', payload: { newCatalogs, selectedCatalogId } });
  };

  const setDefault = () => {
    dispatch({ type: 'setDefault' });
  };


  const catalogsContextValue = useMemo(() => {
    return { ...state, selectCatalog, setCatalogs, setDefault };
    // eslint-disable-next-line
  }, [ state ]);

  return (
    <CatalogsContext.Provider value={ catalogsContextValue }>
      { children }
    </CatalogsContext.Provider>
  );
};

CatalogsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

