import React, { Fragment } from 'react';
import { Box, Typography, Divider, Button, Chip, Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { utils } from 'src/utils';


const useStyles = makeStyles((theme) => ({
  speciesList: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));


const Ds68SpeciesCard = ({ index, associatedSpeciesList, onClickInButton, onClickInRemove, userIsAdmin, ...ds68Species }) => {
  const classes = useStyles();
  return (
    <Card>
      <CardHeader
        title={
          <Typography>
            <strong>{ index + 1 }.-</strong>
            <Box component='span' fontStyle="italic"> { utils.upperFirst(ds68Species.scientificName) }</Box>
          </Typography>
        }
      />
      <CardContent>
        <Box>
          <strong>Nombre común (ds68): </strong> { ds68Species.commonName }<br/>
          <strong>Especies asociadas: </strong> { associatedSpeciesList.length ? '' : 'Sin especies asociadas' }<br/>
          <Box className={ classes.speciesList }>
            {
              associatedSpeciesList.sort((a, b) => a.scientificName > b.scientificName ? 1 : -1).map(sp =>
                <Chip key={ sp.hash }
                  onDelete={ userIsAdmin ? () => onClickInRemove({ ds68Id: ds68Species.id, speciesHash: sp.hash }) : undefined }
                  label={ <Box component='span' fontStyle="italic"> { utils.upperFirst(sp.scientificName) }</Box>}
                  color="primary"
                />)
            }
          </Box>
        </Box>
      </CardContent>
      { userIsAdmin &&
          <Fragment>
            <Divider />
            <CardActions>
              <Button size="small" color="primary" onClick={ () => onClickInButton(ds68Species) }>
                Asociar especie
              </Button>
            </CardActions>
          </Fragment>
      }
    </Card>
  );
};

Ds68SpeciesCard.propTypes = {
  userIsAdmin: PropTypes.bool.isRequired,
  onClickInButton: PropTypes.func,
  onClickInRemove: PropTypes.func,
  index: PropTypes.number,
  id: PropTypes.number,
  scientificName: PropTypes.string,
  commonName: PropTypes.string,
  associatedSpeciesList: PropTypes.arrayOf(PropTypes.shape({
    hash: PropTypes.string,
    scientificName: PropTypes.string,
  })),
};

export { Ds68SpeciesCard };
