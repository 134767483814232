import React, { useState, useContext, useMemo, useCallback, useRef, useEffect } from 'react';
import { Card, CardContent, Typography, Box, Divider, Button, CircularProgress, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { getCurrentUser } from 'src/utils/utils';
import { CatalogsContext } from 'src/contexts';
import { floraApi, faunaApi } from 'src/services';


const editCatalogDescriptionApis = {
  flora: floraApi.editCatalogDescription,
  fauna: faunaApi.editCatalogDescription,
};

const useStyles = makeStyles({
  descriptionContainer: {
    marginTop: 30,
    minHeight: '15rem',
  },
  description: {
    whiteSpace: 'pre-wrap',
  },
  emptyDescription: {
    fontStyle: 'italic',
    color: 'grey',
  },
  divider: {
    marginBottom: 18,
  },
  descriptionTextArea: {
    resize: 'none',
    width: '100%',
  },
});

const CatalogInfoContainer = ({ apiType }) => {
  const { catalogs, selectedCatalogId, setCatalogs } = useContext(CatalogsContext);
  const selectedCatalog = useMemo(() => catalogs?.find(cat => cat.id === selectedCatalogId), [ selectedCatalogId, catalogs ]);
  const classes = useStyles();

  const textAreaRef = useRef();

  const [ editing, setEditing ] = useState(false);
  const setEditionOn = useCallback(() => {
    setErrorMsg(false);
    setSucceeded(false);
    setEditing(true);
  }, []);

  const [ descriptionVal, setDescriptionVal ] = useState(selectedCatalog?.description ?? '');
  const [ errorMsg, setErrorMsg ] = useState('');
  const [ succeeded, setSucceeded ] = useState(false);

  useEffect(() => {
    setEditing(false);
    setErrorMsg(false);
    setSucceeded(false);
    // para resetear el valor del textArea cuando se cambia de catálogo (si no, retiene el del último catálogo que fuiste a editar)
    setDescriptionVal(selectedCatalog?.description ?? '');
    if (textAreaRef?.current?.value) {
      textAreaRef.current.value = selectedCatalog?.description ?? '';
    }
  // eslint-disable-next-line
  }, [ selectedCatalogId ]);

  const submitEdition = async () => {
    const currentDescriptionValue = textAreaRef?.current?.value?.trim() ?? '';
    setDescriptionVal(currentDescriptionValue);
    try {
      const editRequester = editCatalogDescriptionApis[apiType];
      const { description } = await editRequester({ catalogId: selectedCatalogId, description: currentDescriptionValue });

      const newCatalogs = catalogs.map(cat => cat.id === selectedCatalogId ? { ...cat, description } : cat);
      setCatalogs({ newCatalogs, selectedCatalogId });

      setErrorMsg('');
      setSucceeded(true);
      setEditing(false);
    } catch (e) {
      console.error(e);
      setSucceeded(false);
      setErrorMsg(e.serverMessage || 'Ha ocurrido un error desconocido');
    }
  };

  const cancelEdition = useCallback(() => {
    const currentDescriptionValue = textAreaRef?.current?.value;
    // para que el elemento se acuerde de lo último escrito cuando vaya a editar de nuevo (por si se cancela a casualidad)
    setDescriptionVal(currentDescriptionValue);
    setErrorMsg('');
    setSucceeded(false);
    setEditing(false);
  }, []);

  const userIsAdmin = getCurrentUser().role === 'admin';

  return !selectedCatalog ? <CircularProgress/> : <Box>
    <Typography variant="h6" display="inline">
      { selectedCatalog.display_name }
    </Typography>
    <Card className={classes.descriptionContainer}>
      <CardContent>
        { userIsAdmin &&
          <>
            { editing ?
              <>
                <Button color="inherit" onClick={cancelEdition}>Cancelar</Button>
                <Button color="primary" onClick={submitEdition}>Terminar</Button>
              </>
              : <Button color="primary" onClick={setEditionOn}>Editar</Button>
            }
            <Divider className={classes.divider}/>
          </>
        }
        { editing ?
          <>
            <TextField
              inputRef={textAreaRef}
              placeholder="Escribe una descripción para el catálogo"
              defaultValue={selectedCatalog.description ? selectedCatalog.description : descriptionVal}
              className={classes.descriptionTextArea}
              maxRows={Infinity}
              minRows={7}
              variant="outlined"
              multiline
            />
          </>
          : selectedCatalog.description ?
            <Typography component='div' className={classes.description}>
              { selectedCatalog.description }
            </Typography>
            : <Typography className={classes.emptyDescription}>
              Sin descripción
            </Typography>
        }
      </CardContent>
    </Card>
    <Box mt={2}>
      { succeeded && <Alert severity="success">
        Catálogo editado exitosamente
      </Alert>
      }
      { errorMsg && <Alert severity="error">
        { errorMsg }
      </Alert>
      }
    </Box>
  </Box>;
};

CatalogInfoContainer.propTypes = {
  apiType: PropTypes.oneOf([ 'flora', 'fauna' ]).isRequired,
};


export { CatalogInfoContainer };
