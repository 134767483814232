import { createTheme } from '@material-ui/core';


const palette = {
  common: {
    black: 'rgba(46, 46, 46, 1)',
    white: 'rgba(255, 255, 255, 1)',
  },
  background: {
    paper: 'rgba(255, 255, 255, 1)',
    default: 'rgba(250, 250, 250, 1)',
  },
  error: {
    light: '#e57373',
    main: 'rgba(235, 60, 70, 1)',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  success: {
    main: '#c0d72e',
  },
  text: {
    primary: 'rgba(46, 46, 46, 1)',
    secondary: 'rgba(108, 108, 108, 1)',
    disabled: 'rgba(171, 171, 171, 1)',
    hint: 'rgba(155, 155, 155, 1)',
  },
};

const drawerWidth = 180;
const drawerDefaultZIndex = 1200;
const commonStyles = {
  zIndex: {
    appBar: drawerDefaultZIndex + 1,
  },
  overrides: {
    MuiDrawer: {
      root: {
        flexShrink: 0,
        width: drawerWidth,
      },
      paper: {
        width: drawerWidth,
      },
    },
  },
};


const mainTheme = createTheme({ palette, ...commonStyles });

export default mainTheme;