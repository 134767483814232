import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Box, Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Pagination from '@material-ui/lab/Pagination';

import * as jsondiffpatch from 'jsondiffpatch';
import 'jsondiffpatch/dist/formatters-styles/html.css';

import { utils } from 'src/utils';


// no mostrar atributos que no tengan diferencias
jsondiffpatch.formatters.html.hideUnchanged();

const useStyles = makeStyles({
  diff: {
    maxWidth: '73vw',
    overflowX: 'auto',
    '& > div.jsondiffpatch-delta': {
      width: '100%',
    },
    '& div.jsondiffpatch-child-node-type-object:before,div.jsondiffpatch-child-node-type-object:after': {
      content: 'none',
    },
    '& .jsondiffpatch-delta ul': {
      padding: 0,
    },
    '& .jsondiffpatch-property-name': {
      fontWeight: 'bold',
    },
    '& .jsondiffpatch-value': {
      display: 'block',
      paddingLeft: 15,
    },
  },
});

const ChangedSpeciesList = ({ changedSpecies }) => {
  const classes = useStyles();
  const [ currentPage, setCurrentPage ] = useState(1);

  const sortedSpecies = changedSpecies
    .sort((a, b) => a.scientificName > b.scientificName ? 1 : a.scientificName < b.scientificName ? -1 : 0);

  const onClickInPage = (e, page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  // page variables
  const pageSize = 30;
  const totalPages = Math.ceil(sortedSpecies.length / pageSize);
  const initialItemIndex = (currentPage - 1) * pageSize;

  return (
    <Accordion>
      <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
        <Typography>Especies con cambios menores ({ sortedSpecies.length })</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={ 3 }>
          {
            sortedSpecies.length > pageSize &&
              <Grid item xs={ 12 } container justifyContent="center">
                <Pagination count={ totalPages } page={ currentPage } onChange={ onClickInPage } />
              </Grid>
          }
          {
            sortedSpecies.slice(initialItemIndex, initialItemIndex + pageSize).map((species, index) =>
              <Grid key={ species.hash } item xs={ 12 }>
                <Card>
                  <CardHeader
                    title={
                      <Typography>
                        <strong>{ initialItemIndex + index + 1 }.-</strong>
                        <Box component='span' fontStyle="italic"> { utils.upperFirst(species.scientificName) }</Box>
                      </Typography>
                    }
                  />
                  <CardContent>
                    <Box className={ classes.diff }
                      dangerouslySetInnerHTML={{ __html: jsondiffpatch.formatters.html.format(species.lastDiff, species.partialSpecies) }}
                    />
                  </CardContent>
                </Card>
              </Grid>)
          }
          {
            sortedSpecies.length > pageSize &&
              <Grid item xs={ 12 } container justifyContent="center">
                <Pagination count={ totalPages } page={ currentPage } onChange={ onClickInPage } />
              </Grid>
          }
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

ChangedSpeciesList.propTypes = {
  changedSpecies: PropTypes.array,
};

const Memoized = React.memo(ChangedSpeciesList);
export { Memoized as ChangedSpeciesList };
