import urlJoin from 'url-join';


const makeUrl = (routeObj, ...params) => {
  const paramsPart = params?.length && params.every(param => Boolean(param)) ? params : [ routeObj.params ];
  return urlJoin([ routeObj.typePath, ...paramsPart, routeObj.page ]);
};

export {
  makeUrl,
};
