import React from 'react';
import PropTypes from 'prop-types';

import { faunaApi } from 'src/services';
import { SpeciesUpdateContainer } from 'src/components/containers';


const FaunaSpeciesUpdateContainer = ({ sourceId }) => <SpeciesUpdateContainer api={ faunaApi } sourceId={ sourceId }/>;

FaunaSpeciesUpdateContainer.propTypes = {
  sourceId: PropTypes.string,
};

export { FaunaSpeciesUpdateContainer };

