import React, { useEffect, useContext, useMemo, useState } from 'react';
import { useHistory, matchPath } from 'react-router';
import urlJoin from 'url-join';

import { FloraFaunaLayout } from 'src/layouts/components/FloraFaunaLayout';
import { FloraSpeciesUpdateContainer, FloraEeccContainer, Ds68Container, FloraCatalogInfoContainer } from 'src/pages';
import { eeccApi, ds68Api, floraApi } from 'src/services';
import { CatalogsContext, SourcesContext, AlertsContext, makeEeccCounterAlert, makeDsCounterAlert } from 'src/contexts';
import { makeUrl } from 'src/layouts/utils';


const routes = [
  {
    name: 'Actualiza el Listado de Especies',
    typePath: '/flora',
    params: '/:catalogId/:sourceId/',
    page: 'actualizar-especies',
    component: FloraSpeciesUpdateContainer,
  },
  {
    name: 'Asocia Categorías de Conservación',
    typePath: '/flora',
    params: '/:catalogId/:sourceId/',
    page: 'asociar-eecc',
    component: FloraEeccContainer,
  },
  {
    name: 'Asocia DS68',
    typePath: '/flora',
    params: '/:catalogId/:sourceId/',
    page: 'asociar-ds68',
    component: Ds68Container,
  },
  {
    name: 'Info Catálogo',
    typePath: '/flora',
    params: '/:catalogId/:sourceId/',
    page: 'catalog-info',
    component: FloraCatalogInfoContainer,
  },
];

routes.forEach(route => route.path = makeUrl(route));

const Flora = () => {
  const { selectedCatalogId, setCatalogs, setDefault: setDefaultCatalogContext } = useContext(CatalogsContext);
  const { selectedSourceId, setSources, setDefault: setDefaultSourcesContext } = useContext(SourcesContext);
  const { alerts, setAlerts } = useContext(AlertsContext);
  const [ loadings, setLoadings ] = useState({ catalogs: false, sources: false });
  const history = useHistory();

  const currentPath = history.location.pathname;
  // used to see if the initial url is valid and if it includes valid catalog and sources ids
  // eslint-disable-next-line
  const routesMatch = useMemo(() => matchPath(currentPath, { path: routes.map(ru => ru.path), exact: true }), []);

  // helpful to see if we should use history.replace or history.push
  const replaceOrPushUrl = newUrl => {
    const match = matchPath(currentPath, { path: routes.map(ru => ru.path), exact: true });

    const everythingIsValid = match && match.params?.sourceId && match.params.sourceId === selectedSourceId &&
      match.params.catalogId && match.params.catalogId === selectedCatalogId;

    if (everythingIsValid) {
      history.push(newUrl);
    } else {
      history.replace(newUrl);
    }
  };

  // Load catalogs
  useEffect(() => {
    const fetchCatalogs = async () => {
      setLoadings(ps => ({ ...ps, catalogs: false }));
      const fetchedCatalogs = await floraApi.getCatalogs();

      const catUpdate = { newCatalogs: fetchedCatalogs };
      // if the initial url has a catalogId in it, and there is a catalog with that id, set it in the context
      if (routesMatch && routesMatch.params?.catalogId && fetchedCatalogs?.some(cat => cat.id === routesMatch.params.catalogId)) {
        catUpdate.selectedCatalogId = routesMatch.params.catalogId;
      }
      setCatalogs(catUpdate);
      setLoadings(ps => ({ ...ps, catalogs: true }));
    };

    fetchCatalogs();
    // clean up:
    return () => {
      setDefaultCatalogContext();
      setDefaultSourcesContext();
      setAlerts([]);
    };
    // eslint-disable-next-line
  }, []);

  // Load sources of the currently selected catalog
  useEffect(() => {
    const fetchSources = async () => {
      setLoadings(ps => ({ ...ps, sources: false }));
      const fetchedSources = await floraApi.getSourcesInCatalog(selectedCatalogId);
      const sourcesUpdate = { newSources: fetchedSources };

      if (routesMatch && routesMatch.params?.sourceId && fetchedSources?.some(src => src.id === routesMatch.params.sourceId)) {
        sourcesUpdate.selectedSourceId = routesMatch.params.sourceId;
      }

      setSources(sourcesUpdate);
      setLoadings(ps => ({ ...ps, sources: true }));
    };
    if (!loadings.catalogs) {
      return;
    }

    if (selectedCatalogId) {
      fetchSources();
    } else {
      history.replace(urlJoin('/flora', '~', '~'));
    }
    // eslint-disable-next-line
  }, [ selectedCatalogId, loadings.catalogs ]);

  useEffect(() => {
    const loadCounters = async () => {
      const newAlerts = [];
      setAlerts([]);

      const eeccWithoutAssociation = await eeccApi.flora.getCounterWithoutAssociation({ sourceId: selectedSourceId });
      if (eeccWithoutAssociation) {
        newAlerts.push(makeEeccCounterAlert(eeccWithoutAssociation));
      }

      const ds68WithoutAssociation = await ds68Api.getCounterWithoutAssociation({ sourceId: selectedSourceId });
      if (ds68WithoutAssociation) {
        newAlerts.push(makeDsCounterAlert(ds68WithoutAssociation));
      }

      setAlerts(newAlerts);
    };

    if (!loadings.catalogs || !loadings.sources) {
      return;
    }
    const urlCatalogId = selectedCatalogId ?? '~';
    const urlSourceId = selectedSourceId ?? '~';

    const page = currentPath.includes('asociar-eecc') ? 'asociar-eecc'
      : currentPath.includes('asociar-ds68') ? 'asociar-ds68'
        : currentPath.includes('catalog-info') ? 'catalog-info'
          : 'actualizar-especies';
    const newUrl = urlJoin('/flora', urlCatalogId, urlSourceId, page);
    replaceOrPushUrl(newUrl);

    if (selectedSourceId) {
      loadCounters();
    }
    // eslint-disable-next-line
  }, [ selectedSourceId, loadings.catalogs, loadings.sources ]);

  return (
    <FloraFaunaLayout
      name="Flora"
      routes={ routes }
      alerts={ alerts }
    />
  );
};


export { Flora };
