import React from 'react';
import PropTypes from 'prop-types';

import { EeccContainer } from 'src/components/containers';


const FloraEeccContainer = ({ sourceId }) => <EeccContainer eeccApiType='flora' sourceId={ sourceId } />;

FloraEeccContainer.propTypes = {
  sourceId: PropTypes.string,
};

export { FloraEeccContainer };
