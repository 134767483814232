import React, { useState, Fragment, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box,
  Checkbox, FormControlLabel, RadioGroup, Radio, Typography, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { AsyncSpeciesSelector } from 'src/components/inputs';
import { useStyles } from 'src/components/styles';
import { utils } from 'src/utils';
import { ds68Api } from 'src/services';


const SpeciesName = ({ name }) => {
  const classes = useStyles();
  return <Box component="span" className={ classes.speciesName }>{ utils.upperFirst(name || '') }</Box>;
};

SpeciesName.defaultProps = {
  name: '',
};

SpeciesName.propTypes = {
  name: PropTypes.string,
};

const LoadingIndicator = () => <Box display="flex" alignItems="center" justifyContent="center" marginTop={ 2 }>
  <CircularProgress color="primary" />
  <Box marginLeft={ 2 }>Obteniendo datos de categoría de conservación...</Box>
</Box>;

/* eslint-disable react/prop-types */
const BothSpeciesWithCC = ({ preserveOldCC, oldValidCategory, newValidCategory, speciesToRename, speciesSelected, onChange }) =>
  <Fragment>
    <Typography color="secondary" style={ { marginBottom: 0, marginTop: 10 }}>
      Ambas especies tienen asignada una categoría de conservación, por favor escoge cual deseas conservar:
    </Typography>
    <RadioGroup value={ preserveOldCC ? 'old' : 'new' } onChange={ event => onChange(event.target.value === 'old') }>
      <FormControlLabel value='old' control={<Radio />}
        label={ <span><strong>{ oldValidCategory }</strong> de <SpeciesName name={speciesToRename.scientific_name}/></span> }
      />
      <FormControlLabel value='new' control={<Radio />}
        label={ <span><strong>{ newValidCategory }</strong> de <SpeciesName name={speciesSelected.scientific_name}/></span> }
      />
    </RadioGroup>
  </Fragment>;

const OldSpeciesWithCC = ({ preserveOldCC, oldValidCategory, speciesSelected, onChange }) =>
  <Fragment>
    <Typography color="secondary" style={ { marginBottom: 0, marginTop: 10 }}>
      La especie que estás re-nombrando está en categoría de conservación { '"' }{ oldValidCategory }{ '"' }.
      ¿Deseas que <SpeciesName name={ speciesSelected.scientific_name }/> también la tenga?
    </Typography>
    <FormControlLabel
      control={
        <Checkbox checked={ preserveOldCC } onChange={ event => onChange(event.target.checked)} />
      }
      label="Sí, heredar categoría de conservación"
    />
  </Fragment>;

const NewSpeciesWithCC = ({ newValidCategory }) =>
  <Fragment>
    <Typography color="secondary" style={ { marginBottom: 0, marginTop: 10 }}>
      La especie a la que estás re-nombrando está en categoría de conservación { '"' }{ newValidCategory }{ '"' }.
      Esta se mantendrá
    </Typography>
  </Fragment>;
/* eslint-enable react/prop-types */

/* eslint-disable react/prop-types */
const InheritDs68 = ({ speciesToRename, speciesSelected }) =>
  <Typography color="secondary" style={ { marginBottom: 0, marginTop: 10 }}>
    Debido a que {'"'}<SpeciesName name={speciesToRename.scientific_name}/>{'"'} estaba en el Ds68,
    ahora {'"'}<SpeciesName name={speciesSelected.scientific_name}/>{'"'} también lo estará.
  </Typography>;
/* eslint-enable react/prop-types */

const maxResults = 20;
const SpeciesRenameSearcher = ({ open, onCloseDialog, onConfirm, speciesToRename, api }) => {
  const [ speciesSelected, setSpeciesSelected ] = useState();
  const [ preserveOldCC, setPreserveOldCC ] = useState(false);
  const [ oldValidCategory, setOldValidCategory ] = useState();
  const [ newValidCategory, setNewValidCategory ] = useState();
  const [ oldIsDs68, setOldIsDs68 ] = useState(false);
  const [ newIsDs68, setNewIsDs68 ] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!speciesToRename.hash) {
      return;
    }

    const getOldValidCategory = async () => {
      const old = await api.getValidCategory(speciesToRename.hash);
      setOldValidCategory(old);
    };
    const getOldDs68 = async () => {
      const isDs68 = await ds68Api.checkIfSpeciesIsInDs68(speciesToRename.hash);
      setOldIsDs68(isDs68);
    };

    getOldValidCategory();
    if (history.location.pathname.includes('flora')) {
      getOldDs68();
    }

    return () => {
      setOldValidCategory(undefined);
      setSpeciesSelected(undefined);
      setOldIsDs68(false);
    };
    // eslint-disable-next-line
  }, [ speciesToRename ]);

  useEffect(() => {
    const getNewValidCategory = async () => {
      const newCategory = await api.getValidCategory(speciesSelected.hash);
      setNewValidCategory(newCategory);
      setPreserveOldCC(newCategory ? false : true);
    };
    const getNewDs68 = async () => {
      const isDs68 = await ds68Api.checkIfSpeciesIsInDs68(speciesSelected.hash);
      setNewIsDs68(isDs68);
    };
    if (speciesSelected) {
      getNewValidCategory();
      if (history.location.pathname.includes('flora')) {
        getNewDs68();
      }
    }
    return () => {
      setNewValidCategory(undefined);
      setNewIsDs68(false);
    };
    // eslint-disable-next-line
  }, [ speciesSelected ]);

  const returnData = () => onConfirm({ newSpecies: speciesSelected, preserveOldCC });
  const submitOnEnter = e => e.key === 'Enter' && speciesSelected && !loadingData && returnData();

  const loadingData = newValidCategory === undefined || oldValidCategory === undefined;
  const renderOptions = speciesSelected && !loadingData;

  const propsToCCOptions = { preserveOldCC, oldValidCategory, newValidCategory, speciesToRename, speciesSelected,
    onChange: o => setPreserveOldCC(o),
  };

  return <Dialog open={ open } onClose={ onCloseDialog } aria-labelledby="species-rename-modal-title" onKeyPress={ submitOnEnter }>
    <DialogTitle id="species-rename-modal-title">
      ¿Renombrar &quot;<SpeciesName name={ speciesToRename.scientific_name }/>&quot;?
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        Comienza a escribir para realizar la búsqueda, se mostrará un máximo de { maxResults } resultados.
        Si te aparece <strong>(s)</strong> es porque tu búsqueda calzó con la sinonimia de la especie.
      </DialogContentText>
      <AsyncSpeciesSelector maxResults={ maxResults }
        onSelect={ s => setSpeciesSelected(s) }
        searchController={ api.searchToRename }
        optionsFilter={ sp => !speciesToRename.rename_targets.includes(sp.hash) }
      />
      { speciesSelected && loadingData && <LoadingIndicator /> }
      { renderOptions && oldValidCategory && newValidCategory && <BothSpeciesWithCC { ...propsToCCOptions } /> }
      { renderOptions && oldValidCategory && !newValidCategory && <OldSpeciesWithCC { ...propsToCCOptions } /> }
      { renderOptions && !oldValidCategory && newValidCategory && <NewSpeciesWithCC { ...propsToCCOptions } /> }
      { renderOptions && oldIsDs68 && !newIsDs68 && <InheritDs68 { ...propsToCCOptions } /> }
    </DialogContent>
    <DialogActions>
      <Button onClick={ onCloseDialog } color="primary">
        Cancelar
      </Button>
      <Button disabled={ !speciesSelected || loadingData } onClick={ returnData } color="primary">
        Renombrar
      </Button>
    </DialogActions>
  </Dialog>;
};

SpeciesRenameSearcher.propTypes = {
  open: PropTypes.bool,
  onCloseDialog: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  speciesToRename: PropTypes.object,
  api: PropTypes.shape({
    getValidCategory: PropTypes.func.isRequired,
    searchToRename: PropTypes.func.isRequired,
  }),
};

export { SpeciesRenameSearcher };