import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography, InputBase, IconButton, Tooltip, Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';

import { EeccSpeciesPanel } from 'src/components/containers/eecc/components/EeccSpeciesPanel';
import { AssociationDialog } from 'src/components/containers/eecc/components/AssociationDialog';
import { eeccApi } from 'src/services';
import { getCurrentUser } from 'src/utils/utils';
import { AlertsContext } from 'src/contexts';

// TODO: is it possible to avoid rerendering everything when it's just one panel that changes?
// could Memo the panel component, but then you also have to have useMemo/useCallback on its props, and
// some of them depend on eeccSpecies, which would always change?
const EeccContainer = ({ eeccApiType, sourceId }) => {
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ eeccSpecies, setEeccSpecies ] = useState([]);
  const [ modalIsOpen, setModalIsOpen ] = useState(false);
  const [ eeccSpeciesSelected, setEeccSpeciesSelected ] = useState({});
  const [ textFilter, setTextFilter ] = useState('');
  const [ loadingInfo, setLoadingInfo ] = useState(true);
  const { updateEeccCounterAlert } = useContext(AlertsContext);

  const userIsAdmin = getCurrentUser().role === 'admin';

  useEffect(() => {
    const loadInfo = async () => {
      setLoadingInfo(true);
      const eeccSpecies = await eeccApi[eeccApiType].getAssociations({ sourceId });
      setEeccSpecies(eeccSpecies);
      setLoadingInfo(false);
    };
    loadInfo();
    // eslint-disable-next-line
  }, [ sourceId ]);

  useEffect(() => {
    if (loadingInfo) {
      // without this, it sets the count to zero for a bit because eeccSpecies is empty while it's loading
      return;
    }
    const count = eeccSpecies.filter(sp => sp.associatedSpeciesList.length === 0).length;
    updateEeccCounterAlert(count);
    // eslint-disable-next-line
  }, [ eeccSpecies ]);

  const openModal = sp => {
    setEeccSpeciesSelected(sp);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEeccSpeciesSelected({});
  };

  const associateSpecies = async sp => {
    const newAssociatedSpecies = await eeccApi[eeccApiType].associateSpecies({
      eeccHash: eeccSpeciesSelected.hash,
      speciesHash: sp.hash,
    });

    setEeccSpecies(eeccSpecies.map(es => es.hash === eeccSpeciesSelected.hash ?
      { ...es, associatedSpeciesList: [ ...es.associatedSpeciesList, newAssociatedSpecies ] } : es));
    closeModal();
  };

  const desassociateSpecies = async ({ eeccHash, speciesHash }) => {
    const speciesRemovedHash = await eeccApi[eeccApiType].desassociateSpecies({ speciesHash });

    setEeccSpecies(eeccSpecies.map(es => es.hash === eeccHash ?
      { ...es, associatedSpeciesList: es.associatedSpeciesList.filter(ds => ds.hash !== speciesRemovedHash) } : es));
    closeModal();
  };

  const onInputChange = text => {
    setTextFilter(text.toLowerCase().trim());
    setCurrentPage(1);
  };

  // filtro de especies
  const eeccSpeciesFiltered =
    textFilter === 'no asociadas' ? eeccSpecies.filter(es => es.associatedSpeciesList.length === 0)
      : textFilter === 'asociadas' ? eeccSpecies.filter(es => es.associatedSpeciesList.length > 0)
        : eeccSpecies.filter(es =>
          es.scientificName.includes(textFilter) || es.associatedSpeciesList.some(ds => ds.scientificName.includes(textFilter)),
        );

  // page variables
  const pageSize = 48;
  const totalPages = Math.ceil(eeccSpeciesFiltered.length / pageSize);
  const initialItemIndex = (currentPage - 1) * pageSize;

  return (<>{
    loadingInfo || (!loadingInfo && eeccSpecies?.length) ?
      <>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Typography variant="h6" display="inline">
              Aquí puedes ver todas las asociaciones realizadas entre EECC y nuestras fuentes
            </Typography>
          </Grid>
          { loadingInfo ?
            <Typography variant="h6" display="inline">
              Cargando...
            </Typography> :
            <>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={ 3 }>
                </Grid>
                <Grid item>
                  <Pagination count={ totalPages } page={ currentPage } onChange={ (e, page) => setCurrentPage(page) } />
                </Grid>
                <Grid item xs={ 3 }>
                  <InputBase
                    placeholder="Filtra..."
                    inputProps={{ 'aria-label': 'Filtra...' }}
                    onChange={ e => onInputChange(e.target.value) }
                  />
                  <Tooltip
                    title={
                      <Box fontSize={ 12 }>
                        - Escribe {'"'}<strong>no asociadas</strong>{'"'} para ver las especies sin asociación<br/>
                        - Escribe {'"'}<strong>asociadas</strong>{'"'} para ver las especies asociadas<br/>
                      </Box>
                    }
                  >
                    <IconButton aria-label="search"><SearchIcon /></IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              { eeccSpeciesFiltered.slice(initialItemIndex, initialItemIndex + pageSize).map((es, i) =>
                <Grid key={ es.hash } item md={ 6 } lg={ 4 }>
                  <EeccSpeciesPanel { ...{ ...es, index: initialItemIndex + i } }
                    onClickInButton={ openModal } onClickInRemove={ desassociateSpecies } userIsAdmin={ userIsAdmin }
                  />
                </Grid>)
              }
              <Grid container justifyContent="center">
                <Pagination count={ totalPages } page={ currentPage } onChange={ (e, page) => setCurrentPage(page) } />
              </Grid>
            </>
          }
        </Grid>
        <AssociationDialog open={ modalIsOpen } eeccSpecies={ eeccSpeciesSelected }
          onConfirm={ associateSpecies }
          onCloseDialog={ () => setModalIsOpen(false) }
          searchController={ eeccApi[eeccApiType].search }
        />
      </>
      : <Box><Typography variant="h6" display="inline">Esta fuente no tiene especies asociables a EECC</Typography></Box>
  }</>);
};

EeccContainer.propTypes = {
  eeccApiType: PropTypes.oneOf([ 'flora', 'fauna' ]).isRequired,
  sourceId: PropTypes.string,
};


export { EeccContainer };