import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import PropTypes from 'prop-types';


const ApproveVersionDialog = ({ open, onCloseDialog, onConfirm, version }) =>
  <Dialog open={ open } onClose={ onCloseDialog } aria-labelledby="approve-version-modal-title">
    <DialogTitle id="approve-version-modal-title">
      ¿Aprobar versión <strong>{ version }</strong>?
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        Antes de confirmar asegúrate de que las <strong>especies en categoría de conservación</strong> hayan quedado
        bien con los nuevos renombres.<br/>
        Al aprobar la versión actual <strong>no podrás volver a renombrar</strong> especies ni eliminar renombres
        hechos hasta la siguiente versión del listado de especies.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={ onCloseDialog } color="primary">
        Cancelar
      </Button>
      <Button onClick={ onConfirm } color="primary">
        Confirmar
      </Button>
    </DialogActions>
  </Dialog>;

ApproveVersionDialog.propTypes = {
  open: PropTypes.bool,
  onCloseDialog: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  version: PropTypes.number,
};

export { ApproveVersionDialog };