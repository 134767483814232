import React, { Fragment } from 'react';
import { Box, Typography, Divider, Button, Chip, Avatar, Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ComputerIcon from '@material-ui/icons/Computer';
import PropTypes from 'prop-types';

import { utils } from 'src/utils';


const useStyles = makeStyles(theme => ({
  speciesList: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const getInitials = user => [ user.firstName, user.lastName ].map(i => i.replace(/^(.).*/, '$1')).join('').toUpperCase();

const EeccSpeciesPanel = ({ index, associatedSpeciesList, onClickInButton, onClickInRemove, userIsAdmin, ...eeccSpecies }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        title={
          <Typography>
            <strong>{ index + 1 }.-</strong>
            <Box component='span' fontStyle="italic"> { utils.upperFirst(eeccSpecies.scientificName) }</Box>
          </Typography>
        }
      />
      <CardContent>
        <Box>
          <strong>Categoría de conservación: </strong> { eeccSpecies.validCategory }<br/>
          <strong>Decreto: </strong> { eeccSpecies.referenceOrDecree }<br/>
          <strong>RCA: </strong> { eeccSpecies.processRcaNumber }<br/>
          <strong>Fuente: </strong> { eeccSpecies.source }<br/>
          <strong>Especies asociadas: </strong> { associatedSpeciesList.length ? '' : 'Sin especies asociadas' }<br/>
          <Box className={ classes.speciesList }>
            {
              associatedSpeciesList.sort((a, b) => a.scientificName > b.scientificName ? 1 : -1).map(ds =>
                <Chip key={ ds.hash }
                  { ...(ds.user ?
                    { avatar: <Avatar alt={ `${ds.user.firstName} ${ds.user.lastName}` }>{ getInitials(ds.user) }</Avatar> }
                    : { icon: <ComputerIcon fontSize="small" /> })
                  }
                  onDelete={ userIsAdmin ? () => onClickInRemove({ eeccHash: eeccSpecies.hash, speciesHash: ds.hash }) : undefined }
                  label={ <Box component='span' fontStyle="italic"> { utils.upperFirst(ds.scientificName) }</Box>}
                  color="primary"
                />)
            }
          </Box>
        </Box>
      </CardContent>
      { userIsAdmin &&
          <Fragment>
            <Divider />
            <CardActions>
              <Button size="small" color="primary" onClick={ () => onClickInButton(eeccSpecies) }>
                Asociar especie
              </Button>
            </CardActions>
          </Fragment>
      }
    </Card>
  );
};

EeccSpeciesPanel.propTypes = {
  userIsAdmin: PropTypes.bool.isRequired,
  onClickInButton: PropTypes.func,
  onClickInRemove: PropTypes.func,
  index: PropTypes.number,
  hash: PropTypes.string,
  scientificName: PropTypes.string,
  validCategory: PropTypes.string,
  referenceOrDecree: PropTypes.string,
  processRcaNumber: PropTypes.string,
  source: PropTypes.string,
  associatedSpeciesList: PropTypes.arrayOf(PropTypes.shape({
    hash: PropTypes.string,
    scientificName: PropTypes.string,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
    }),
  })),
};

export { EeccSpeciesPanel };
